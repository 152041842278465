<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <b-modal
                    ref="toman-modal"
                    title="شبکه ها"
                    v-model="modal"
            >
                <b-overlay :show="loading"
                           rounded="sm">
                    <CryptoCurrencyTable :items="nets.tokenTypeDetails" :columns="cols" :coin="nets.coin"/>
                </b-overlay>
                <template #modal-footer>
                    <div></div>
                </template>
            </b-modal>
            <b-row>
                <b-col cols="12" md="12">
                    <CryptoCurrencyTable :items="items" :columns="columns" @click="setFee"/>
                </b-col>
            </b-row>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BCard,
        BOverlay,
        BRow,
        BCol,
        // BButton,
    } from 'bootstrap-vue'
    import CryptoCurrencyTable from "@/views/CryptoCurrency/CryptoCurrencyTable";

    export default {
        name: "CryptoCurrency",
        components: {
            CryptoCurrencyTable,
            BCard,
            BOverlay,
            BRow,
            BCol,
        },
        data: () => ({
            dir: false,
            loading: false,
            coins: [],
            items: [],
            items1: [],
            data: {},
            edit: false,
            type: false,
            modal:false,
            columns: [
                {
                    label: 'رمز ارز',
                    key: 'coin',
                    sortable: false,
                },
            ],
            cols:[
                {
                    label: 'شبکه',
                    key: 'tokenType',
                    sortable: false,
                },
                {
                    label: 'کارمزد برداشت',
                    key: 'withdrawalFee',
                    sortable: false,
                },
                {
                    label: 'حداقل برداشت',
                    key: 'minWithdraw',
                    sortable: false,
                },
                {
                    label: 'حداکثر برداشت',
                    key: 'maxWithdraw',
                    sortable: false,
                },
            ],
            nets:[

            ]
        }),
        methods: {
            async getData() {
                this.state.loading = true

                const res = await this.$axios.get('/coins?p2pSupported=true')
                if (Object.hasOwnProperty.call(res.data.content[0],'minAmount')) {
                    this.columns.push(
                        {
                            label: 'حداقل سفارش',
                            key: 'minAmount',
                            sortable: false,
                        },
                        {
                            label: 'حداکثر سفارش',
                            key: 'maxAmount',
                            sortable: false,
                        },
                        {
                            label: 'شبکه ها',
                            key: 'tokenTypeDetails',
                            sortable: false,
                        },
                    )
                }
                if (Object.hasOwnProperty.call(res.data.content[0],'creditCardFee')) {
                    this.columns.push(
                        {
                            label: 'کارمزد کارت ها',
                            key: 'creditCardFee',
                            sortable: false,
                        },
                    )
                }
                this.items = res.data.content
            },
            setFee(e){
                this.nets=e
                this.modal=true
            }
        },
        async created() {
            await this.getData()
        }
    }
</script>

<style scoped>

</style>